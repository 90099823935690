import React, { useState, useEffect, useMemo, useCallback } from "react";
import Select from "react-select";
import axios from "axios";
import { itemsToExclude } from "../utils/mappings";
import { fetchItems } from "../utils/fetchItemData";

const Calculator = () => {
  const [items, setItems] = useState([]);
  const [levelsData, setLevels] = useState([]);
  const [currentExp, setCurrentExp] = useState(1);
  const [currentLvl, setcurrentLvl] = useState(1);
  const [targetLevel, setTargetLevel] = useState(currentLvl + 1);
  const [expNeeded, setExpNeeded] = useState(0);
  const [username, setUsername] = useState("");
  const [skill, setSkill] = useState("");
  const [error, setError] = useState("");
  const [recipes, setRecipes] = useState([]);
  const [recipeData, setRecipeData] = useState(null);
  const [selectedRecipe, setSelectedRecipe] = useState("");
  const [achievementsData, setAchievementsData] = useState({});
  const [quantity, setQuantity] = useState("");
  const [kits, setKits] = useState(1);
  const [totalMarketPrice, setTotalMarketPrice] = useState(0);
  const [totalCraftPrice, setTotalCraftPrice] = useState(0);
  const [error2, setError2] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [farmingItems, setFarmingItems] = useState([]);
  const [calculatedQuantity, setCalculatedQuantity] = useState(0);
  const [baseEnergy, setBaseEnergy] = useState(0);
  const [waterEnergy, setWaterEnergy] = useState(0);
  const [soilCount, setSoilCount] = useState(1);
  const [seedCost, setSeedPrice] = useState(0);
  const [growTime, setGrowTime] = useState(0);
  const [totalEnergy, setTotalEnergy] = useState(0);
  const [error3, setError3] = useState([]);
  const [message3, setMessage3] = useState([]);
  const [calculatedQuantity2, setCalculatedQuantity2] = useState(0);
  const [totalEnergy2, setTotalEnergy2] = useState(0);
  const [resource, setSelectedResource] = useState("");
  const [tierOpts, setTierOptions] = useState([]);
  const [selectedTierValue, setSelectedTier] = useState("");
  const [error4, setError4] = useState([]);
  const [message4, setMessage4] = useState([]);
  const [energyDisplay, setEnergyDisplay] = useState([]);
  const [selectedEnergyItem, setSelectedEnergyItem] = useState(null);
  const [energyAmount, setEnergy] = useState(0);
  const [calculatedQuantity3, setCalculatedQuantity3] = useState(0);
  const [error5, setError5] = useState([]);

  const industryMap = useMemo(
    () => ({
      farming: {
        name: "Farming",
        type: "crafting",
        industries: { windmill: "Windmill", farming: "Farming" },
      },
      woodwork: {
        name: "Woodworking",
        type: "crafting",
        industries: {
          woodwork: "Woodworking",
        },
      },
      cooking: {
        name: "Cooking",
        type: "crafting",
        industries: { cooking: "Cooking", landbbq: "Barney's BBQ", waterbbq: "Submarine Sizzler", spacebbq: "Galactic Grill" },
      },
      metalworking: {
        name: "Metalworking",
        type: "crafting",
        industries: {
          metalworking: "Metalworking",
        },
      },
      stoneshaping: {
        name: "Stoneshaping",
        type: "crafting",
        industries: { stoneshaping: "Stoneshaping" },
      },
      petcare: {
        name: "Animal Care",
        type: "crafting",
        industries: {
          landbbq: "Barney's BBQ",
          waterbbq: "Submarine Sizzler",
          spacebbq: "Galactic Grill",
        },
      },
      business: {
        name: "Business",
        type: "crafting",
        industries: {
          business: "business",
          winery: "Winery",
          compost: "Composter",
          shake: "Milkshake Machine",
          juicer: "Shorelime Juicer",
          coffee: "Latte Machine",
          quantum_recombinator: "Quantum Recombinator",
          machine: "The Machine",
          textile_mill: "Textiler",
        },
      },
      forestry: {
        name: "Forestry",
        type: "resource",
        exp: [
          {
            tier: "Tier 1 Tree (0 - 20)",
            exp: 14,
            energy: 9,
          },
          {
            tier: "Tier 2 Tree (20 - 40)",
            exp: 99,
            energy: 9,
          },
          {
            tier: "Tier 3 Tree (40 - 60)",
            exp: 279,
            energy: 9,
          },
          {
            tier: "Tier 4 Tree (60 - 80)",
            exp: 612,
            energy: 9,
          },
        ],
      },
      mining: {
        name: "Mining",
        type: "resource",
        exp: [
          {
            tier: "Tier 1 Mine (0 - 20)",
            exp: 40,
            energy: 4,
          },
          {
            tier: "Tier 2 Mine (20 - 40)",
            exp: 100,
            energy: 4,
          },
          {
            tier: "Tier 3 Mine (40 - 60)",
            exp: 200,
            energy: 4,
          },
          {
            tier: "Tier 4 Mine (60 - 80)",
            exp: 240,
            energy: 4,
          },
        ],
      },
      petcare2: {
        name: "Animal Care",
        type: "resource",
        exp: [
          {
            tier: "Coop",
            exp: 2,
            energy: 1.5,
          },
          {
            tier: "Apiaries",
            exp: 1,
            energy: 2.5,
          },
          {
            tier: "Slug Hutch",
            exp: 2,
            energy: 5,
          },
        ],
      },
      boxing: {
        name: "Bazaarn Boxing",
        type: "crafting",
        industries: {
          cookboxing: "Cooking Boxes",
          mineboxing: "Mining Boxes",
          businessboxing: "Business Boxes",
          metalboxing: "Metalworking Boxes",
          stoneboxing: "Stoneshaping Boxes",
          forestboxing: "Forestry Boxes",
          animalboxing: "Animal Care Boxes",
          farmboxing: "Farming Boxes",
          woodboxing: "Woodworking Boxes",
        },
      },
    }),
    []
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const levelResponse = await fetch("https://api.pixelore.wiki/api/levels");
        if (!levelResponse) {
          throw new Error(`HTTP error! Status: ${levelResponse.status}`);
        }

        const levels = await levelResponse.json();
        setLevels(levels);
        fetchItems(setItems);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    fetchData();
  }, []);

  const itemsData = useMemo(() => items, [items]);
  const levels = useMemo(() => levelsData, [levelsData]);

  useEffect(() => {
    const calculateExpNeeded = (currentExp, targetLevel, currentLvl) => {
      // Reset error message
      setError("");

      // Ensure the level and EXP are numbers
      const parsedCurrentExp = parseFloat(currentExp);
      const parsedTargetLevel = parseInt(targetLevel, 10);
      const parsedCurrentLevel = parseInt(currentLvl, 10);

      // Check if currentExp or targetLevel are valid numbers
      if (isNaN(parsedCurrentExp) || isNaN(parsedTargetLevel) || isNaN(parsedCurrentLevel)) {
        setExpNeeded(0);
        setError("Please enter valid numbers for Levels & EXP.");
        return;
      }

      // Convert levels object to an array
      const levelsArray = Object.values(levels);

      // Validate the target level
      if (parsedTargetLevel < 1 || parsedTargetLevel > levelsArray.length) {
        setExpNeeded(0);
        setError("Target level is outside the valid range.");
        return;
      }

      if (parsedCurrentLevel < 1 || parsedCurrentLevel > levelsArray.length - 1) {
        setExpNeeded(0);
        setError("Current level is outside the valid range.");
        return;
      }

      // Find the target level data
      const targetLevelData = levelsArray.find((levelData) => levelData.level === parsedTargetLevel);
      const currentLevelData = levelsArray.find((levelData) => levelData.level === parsedCurrentLevel);

      if (!targetLevelData) {
        setExpNeeded(0);
        setError("Invalid target level.");
        return;
      }

      if (!currentLevelData) {
        setExpNeeded(0);
        setError("Invalid current level.");
        return;
      }

      // Validate the current EXP
      const maxExp = levelsArray[levelsArray.length - 1].exp;
      if (parsedCurrentExp > maxExp) {
        setExpNeeded(0);
        setError("Current EXP exceeds the maximum possible value.");
        return;
      }

      // Calculate needed EXP
      const currentExperience = currentLevelData.exp + parsedCurrentExp;
      const neededExp = targetLevelData.exp - currentExperience;
      setExpNeeded(neededExp > 0 ? neededExp.toLocaleString() : 0);
    };

    if (currentExp && targetLevel && currentLvl) {
      calculateExpNeeded(currentExp, targetLevel, currentLvl);
    } else {
      setExpNeeded(0);
    }
  }, [currentExp, currentLvl, targetLevel, levels]);

  // Handlers
  const handleLvlChange = (e) => {
    setcurrentLvl(e.target.value);
  };

  const handleExpChange = (e) => {
    setCurrentExp(e.target.value);
  };

  const handleLevelChange = (e) => {
    setTargetLevel(e.target.value);
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handleSkillChange = (e) => {
    setSkill(e.value);
  };

  const handleDataFetch = async () => {
    setError("");
    setTargetLevel(0);
    const name = username;
    const lookupSkill = skill;
    try {
      const response = await axios.get("https://api.pixelore.wiki/api/playerlookup", {
        params: {
          username: name,
          detail: 5,
          quests: true,
        },
      });

      if (response.data) {
        const skillData = response.data.levels[lookupSkill];

        if (skillData) {
          setcurrentLvl(skillData.level);
          setCurrentExp(skillData.exp);
        } else {
          setcurrentLvl(1);
          setCurrentExp(0);
          setError(`Skill '${skill}' not found for this player.`);
        }
      } else {
        setError("No player was found with that username.");
      }
    } catch (error) {
      setError("An error occurred while fetching the player data.");
      console.error("Error fetching data:", error);
    }
  };

  const skillOptions = Object.keys(industryMap).map((key) => ({
    value: key,
    label: industryMap[key].name,
  }));

  // Everything below is for the Crafting Recipe Calculator
  //Handlers
  const handleIndustryChange = (selectedOption) => {
    setSelectedRecipe("");
    setRecipeData(null);
    filterRecipes(selectedOption.value);
  };

  const handleRecipeChange = (selectedOption) => {
    const selectedRecipeId = selectedOption.value;
    setSelectedRecipe(selectedRecipeId);

    // Retrieve the stored achievement data
    const selectedRecipeData = achievementsData[selectedRecipeId];

    if (selectedRecipeData) {
      setRecipeData(selectedRecipeData);
      setError2("");
    } else {
      setError2("Recipe data not found.");
      setRecipeData(null);
    }
  };

  //Functions
  //Displays Recipes available for the selected Industry
  const filterRecipes = (industry) => {
    if (!industryMap[industry]) return;

    const requiredSkills = Object.keys(industryMap[industry].industries);
    let filteredItems;

    if (industry === "boxing") {
      //Get items that match the business industry
      filteredItems = itemsData.filter((item) => {
        const requiredSkill = item.craftable;
        return requiredSkills.includes(requiredSkill) && !itemsToExclude.includes(item.id);
      });
    } else {
      filteredItems = itemsData.filter((item) =>
        item.recipes?.some((recipe) => {
          const requiredSkill = recipe.expType;
          return requiredSkill === industry;
        })
      );
    }

    // Store achievement data for quick lookup
    const achievements = {};
    filteredItems.forEach((item) => {
      item.recipes?.forEach((recipe) => {
        achievements[recipe.id] = recipe;
      });
    });
    setAchievementsData(achievements);

    // Prepare recipes for the dropdown
    const recipesList = filteredItems
      .flatMap((item) =>
        item.recipes
          .filter((recipe) => !itemsToExclude.includes(recipe.id))
          .map((recipe) => ({
            value: recipe.id,
            label: (
              <div>
                <img src={item.image} alt={recipe.name} width="20" height="20" className="inline" style={{ marginRight: "8px" }} />
                {recipe.name} (Level: {recipe.level || 0})
              </div>
            ),
            level: recipe.level || 0, // Ensure level is part of the object
          }))
      )
      .sort((a, b) => a.level - b.level);

    setRecipes(recipesList);
  };

  const parseCost = (cost) => parseFloat(cost.replace(/,/g, "")) || 0;

  const calculateResults = useMemo(() => {
    if (!recipeData || quantity <= 0) {
      return [];
    }

    setError(""); // Clear the error
    setError2(""); // Clear the second error if calculation is valid

    let totalMarket = 0;
    let totalCraft = 0;
    // Map over the recipeData to calculate market price and total quantities
    const results = recipeData.requiredItems.map((item) => {
      // Find the matching item in itemsData
      const itemData = itemsData.find((data) => data.name === item.name);

      // If no itemData is found, handle it with defaults
      if (!itemData) {
        console.log(`Item with ID ${item.id} not found in itemsData`);
        return {
          id: item.id,
          name: "Unknown Item",
          image: "",
          totalQuantity: 0,
          mpValue: 0,
          craftingCost: 0,
        };
      }

      // Calculate market price and total quantities
      const totalQuantity = item.quantity * quantity;
      const mpValue = item.totalCost * quantity;
      totalMarket += mpValue;
      const craftCost = itemData.craftingCost !== "N/A" ? (itemData.craftingCost * totalQuantity) : 0;
      totalCraft += craftCost > 0 ? craftCost : mpValue;

      return {
        id: item.id,
        name: itemData.name || "Unknown Item",
        image: itemData.image || "",
        totalQuantity,
        mpValue, // Store the calculated market price value
        craftCost: craftCost,
      };
    });

    setTotalMarketPrice(totalMarket); // Update total market price state
    setTotalCraftPrice(totalCraft);

    return results;
  }, [recipeData, quantity, itemsData]);

  const industryOptions = Object.keys(industryMap)
    .filter((key) => industryMap[key].type === "crafting")
    .map((key) => ({
      value: key,
      label: industryMap[key].name,
    }));

  const energyUsage = recipeData?.energyCost || 0;
  const exp = recipeData?.exp || 0;
  const time = recipeData?.time || 0;

  const calculateRequiredQuantity = () => {
    if (exp > 0) {
      if (!expNeeded) {
        setError2("You need to set a Target Level in the Level Calculator");
        setError("Over here~");
      } else {
        // Remove commas and convert to numbers
        const numericExpNeeded = Number(expNeeded.replace(/,/g, ""));
        const numericExp = Number(exp);
        const calculatedQuantity = Math.ceil(numericExpNeeded / numericExp);
        return calculatedQuantity;
      }
    }

    setError2("You need to set a Target Level in the Level Calculator");
    setError("Over here~");
    return 1;
  };

  const handleAutoQuantityClick = () => {
    const calculatedQuantity = calculateRequiredQuantity();
    setQuantity(calculatedQuantity);
  };

  const formatTime = (minutes) => {
    if (minutes >= 1440) {
      // 1440 minutes in a day
      const days = Math.floor(minutes / 1440);
      const remainingHours = Math.round((minutes % 1440) / 60);
      return `${days} day${days > 1 ? "s" : ""}${remainingHours ? ` ${remainingHours} hour${remainingHours > 1 ? "s" : ""}` : ""}`;
    } else if (minutes >= 60) {
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = Math.round(minutes % 60);
      return `${hours} hour${hours > 1 ? "s" : ""}${remainingMinutes ? ` ${remainingMinutes} minute${remainingMinutes > 1 ? "s" : ""}` : ""}`;
    } else {
      return `${minutes.toFixed(2)} minute(s)`;
    }
  };

  //Farming Calculator
  // useMemo for recipeDisplay (remains unchanged)
  const recipeDisplay = useMemo(() => {
    const filteredItems = itemsData.filter((item) => item.type === "Farming" && item.plantable && item.plantable.growthTime && item.name && !itemsToExclude.includes(item.id));

    const itemList = filteredItems
      .map((item) => ({
        value: item.id,
        label: (
          <div>
            <img src={item.image} alt={item.name} width="20" height="20" className="inline" style={{ marginRight: "8px" }} />
            {item.name} (Level: {item.plantable.level || 0})
          </div>
        ),
        level: item.plantable.level  || 0,
      }))
      .sort((a, b) => a.level - b.level);

    // Populate farmingItems state with a mapping of item IDs to item data
    const farmItems = {};
    filteredItems.forEach((item) => {
      farmItems[item.id] = item;
    });
    setFarmingItems(farmItems);

    return itemList;
  }, [itemsData]);

  // useEffect to trigger calculation when expNeeded or other relevant states change
  const calculateItemData = useCallback(
    (farmItemId) => {
      const selectedItem = farmingItems[farmItemId];

      if (selectedItem) {
        const exp = selectedItem.plantable?.exp || 0;
        const energy = selectedItem.plantable?.plantEnergy * -1 || 0;

        if (expNeeded) {
          if (exp > 0) {
            const numericExpNeeded = Number(expNeeded.replace(/,/g, ""));
            const calculatedQuantity = Math.ceil(numericExpNeeded / exp);
            const growthTime = selectedItem.plantable?.growthTime;
            const seedPrice = selectedItem.purchaseable && selectedItem.purchaseable[0]?.buyPrice ? selectedItem.purchaseable[0]?.buyPrice : "N/A";

            const totalEnergy = calculatedQuantity * (energy + (selectedItem.plantable?.harvestEnergy + 0.5));
            setBaseEnergy(calculatedQuantity * (energy + selectedItem.plantable?.harvestEnergy));
            setWaterEnergy(calculatedQuantity * 0.5);
            setCalculatedQuantity(calculatedQuantity);
            setTotalEnergy(totalEnergy);
            setGrowTime(growthTime * calculatedQuantity);
            setSeedPrice(seedPrice * calculatedQuantity || "N/A");
            setError("");
            setError3("");
          } else {
            setError3("EXP value is missing for the selected item.");
          }
        } else {
          setError("Over here~");
          setError3("You need to set a target level in the Level Calculator!");
        }
      } else {
        setError3("Selected item not found");
      }
    },
    [expNeeded, farmingItems]
  );

  useEffect(() => {
    if (calculatedQuantity > 0 && farmingItems[selectedOption?.value]) {
      calculateItemData(selectedOption?.value);
    }
  }, [expNeeded, farmingItems, selectedOption?.value, calculateItemData, calculatedQuantity]);

  const handleFarmItemChange = (selectedOption) => {
    setSelectedOption(selectedOption); // Track the selected option in state
    calculateItemData(selectedOption.value);
    setMessage3("");
  };

  //Resource Calculator
  const handleResourceChange = (selectedOption) => {
    setSelectedResource(selectedOption.value);
    displayTiers(selectedOption.value);
    setMessage4("");
  };

  const handleTierChange = (selectedOption) => {
    setSelectedTier(selectedOption.value);
    calculateItemData2(selectedOption.value);
  };

  const industryDisplay = useMemo(() => {
    return Object.keys(industryMap)
      .filter((key) => industryMap[key].type === "resource")
      .map((key) => ({
        value: key,
        label: industryMap[key].name,
      }));
  }, [industryMap]);

  const displayTiers = useCallback(
    (resourceId) => {
      const selectedItem = industryMap[resourceId];

      if (selectedItem && selectedItem.exp) {
        setError("");
        setError4("");

        const tierOptions = selectedItem.exp.map((tierData, index) => ({
          value: index,
          label: tierData.tier,
        }));

        setTierOptions(tierOptions);
      } else {
        setError4("That is not one of the options or there are no tiers available.");
      }
    },
    [industryMap]
  );

  const calculateItemData2 = useCallback(
    (tier) => {
      const selectedIndustry = industryMap[resource];

      if (selectedIndustry) {
        const tierData = selectedIndustry.exp[tier];
        const exp = tierData.exp;
        const energy = tierData.energy;

        if (expNeeded) {
          if (exp > 0) {
            const numericExpNeeded = Number(expNeeded.replace(/,/g, ""));
            const calculatedQuantity = Math.ceil(numericExpNeeded / exp);

            const totalEnergy = calculatedQuantity * energy;

            setCalculatedQuantity2(calculatedQuantity);
            setTotalEnergy2(totalEnergy);
            setError("");
            setError4("");

            switch (resource){
              case "mining":                
              setMessage4("This does not account for fail rates");
                break;
              case "forestry":
                setMessage4("This does not account for dense/light land drops. Drop EXP is based on the average EXP across all drops. Energy is calculated on average of 6 chops");
                break;
              case "petcare2":
                setMessage4("Does not account for EXP from special items")
                break;
              default:
                setMessage4("");
                break;
            }
          } else {
            setError4("EXP value is missing for the selected item.");
          }
        } else {
          setError("Over here~");
          setError4("You need to set a target EXP!");
        }
      } else {
        setError4("Selected item not found");
      }
    },
    [expNeeded, industryMap, resource]
  );

  useEffect(() => {
    if (resource !== null && expNeeded !== "") {
      // Recalculate whenever selectedResource or expNeeded changes
      const selectedTier = tierOpts.find((option) => option.value === selectedTierValue); // Adjust `selectedTierValue` to your actual state
      if (selectedTier) {
        calculateItemData2(selectedTier.value);
      }
    }
  }, [expNeeded, resource, tierOpts, calculateItemData2, selectedTierValue]);

  //Energy Calculator
  const handleEnergyChange = (selectedOption) => {
    setSelectedEnergyItem(selectedOption);
    setCalculatedQuantity3(0); // Reset the calculated quantity when item changes
  };

  useEffect(() => {
    const energyOptions = () => {
      const energyItems = itemsData.filter((item) => item.energyValue > 50);

      // Convert filtered items to options for the Select component
      const energyDisplay = energyItems.map((item) => ({
        value: item.id,
        image: item.image,
        label: (
          <div>
            <img src={item.image} alt={item.name} width="20" height="20" className="inline" style={{ marginRight: "8px" }} />
            {item.name} (Base Energy: {item.energyValue})
          </div>
        ),
        energyValue: item.energyValue,
        marketPrice: parseCost(item.marketPrice),
        imageUrl: item.imageUrl,
      }));

      setEnergyDisplay(energyDisplay);
    };
    energyOptions();
  }, [itemsData]);

  useEffect(() => {
    const calculateEnergyQuantity = () => {
      if (!selectedEnergyItem) {
        setError5("Please select an energy item.");
        return;
      }

      if (!energyAmount || energyAmount <= 0) {
        setError5("Please enter a valid energy amount.");
        return;
      }

      const energyValue = selectedEnergyItem.energyValue;

      if (energyValue > 0) {
        const requiredQuantity = Math.ceil(energyAmount / energyValue);
        setCalculatedQuantity3(requiredQuantity);
        setError5(""); // Clear any previous errors
      } else {
        setError5("Selected item does not provide energy.");
      }
    };

    if (energyAmount > 0 && selectedEnergyItem) {
      calculateEnergyQuantity();
    }
  }, [energyAmount, selectedEnergyItem]);

  return (
    <div className="justify-center">
      <div className="container mx-auto p-6 rounded-lg shadow-lg bg-retrodp mt-5 md:min-h-[78vh] overflow-auto">
        <h1 className="text-4xl font-heading mb-2 text-center text-retrocream">Calculators</h1>

        <div className="lg:flex md:flex-row md:flex-wrap flex-col grid gap-2 w-full">
          {/*Level Calculator*/}
          <div className="flex-1 bg-retrogray p-2 rounded-lg border-2 border-white">
            <h2 className="starterTitle2 text-center">Level Calculator</h2>
            <h3 className="text-base text-center font-teko">Calculate how much EXP for a specific level</h3>
            <div>
              <div className="flex text-sm flex-row leading-relaxed mt-5 gap-2 justify-center">
                <div className="flex flex-col items-center">
                  <p>Current Level:</p>
                  <input type="number" value={currentLvl} onChange={handleLvlChange} className="border p-1 rounded text-retrogray w-24" placeholder="Enter current LVL" />
                </div>
                <div className="flex flex-col items-center">
                  <p>Current EXP:</p>
                  <input type="number" value={currentExp} onChange={handleExpChange} className="border p-1 rounded text-retrogray w-24" placeholder="Enter current EXP" />
                </div>
                <div className="flex flex-col items-center">
                  <p>Target Level:</p>
                  <input type="number" value={targetLevel} onChange={handleLevelChange} className="border p-1 rounded text-retrogray w-24" placeholder="Enter target level" />
                </div>
              </div>
              <div className="flex items-center space-x-2 text-lg mt-2 justify-center">
                <p>EXP Needed:</p>
                <span>{expNeeded}</span>
              </div>
              <hr></hr>
              <div className="text-sm mt-1">
                Auto-Retrieve Data
                <div>
                  <div className="flex flex-col text-left">
                    <label>Set Your Username:</label>
                    <input type="text" value={username} onChange={handleUsernameChange} className="border p-1 rounded text-retrogray w-full" placeholder="Enter Username" />
                  </div>
                  <div className="flex flex-col text-left">
                    <label>Choose a Skill:</label>
                    <Select onChange={handleSkillChange} options={skillOptions} className="text-retropb w-full" />
                  </div>
                </div>
                <button onClick={handleDataFetch} className="mt-2 bg-retrobg text-xs text-white p-2 rounded">
                  Get Info
                </button>
              </div>
              {error && (
                <div className="text-red-500 mt-2 text-sm">
                  <p>{error}</p>
                </div>
              )}
            </div>
          </div>

          {/*Crafting Recipe Calculator*/}
          <div className="flex-1 bg-retrogray p-2 rounded-lg border-2 border-white">
            <h2 className="starterTitle2 text-center">Crafting Recipe Calculator</h2>
            <h3 className="text-base text-center font-teko">Calculate ingredients to craft multiple items</h3>
            <div className="text-sm">
              <div className="m-1">
                <Select onChange={handleIndustryChange} options={industryOptions} className="text-retropb" placeholder="Select Skill.." />
              </div>
              <div className="m-1">
                <Select
                  value={recipes.find((option) => option.value === selectedRecipe)}
                  onChange={handleRecipeChange}
                  options={recipes}
                  className="text-retropb"
                  placeholder="Select Recipe.."
                />
              </div>
            </div>

            <div className="m-1 text-sm flex-col">
              <div className="flex flex-wrap">
                <div className="flex flex-col w-full md:w-[48%] md:mr-2">
                  <label className="mb-1"># of Items:</label>
                  <input
                    type="number"
                    placeholder="Set Quantity"
                    value={quantity}
                    onChange={(e) => setQuantity(parseInt(e.target.value, 10))}
                    min="1"
                    className="h-8 p-2 w-full text-retrogray border border-gray-300 rounded"
                  />
                </div>
                <div className="flex flex-col w-full md:w-[48%]">
                  <label className="mb-1"># of Machines:</label>
                  <input
                    type="number"
                    placeholder="Number of Kits"
                    value={kits}
                    onChange={(e) => setKits(parseInt(e.target.value, 10))}
                    min="1"
                    className="h-8 p-2 w-full text-retrogray border border-gray-300 rounded"
                  />
                </div>
              </div>
            </div>
            <button onClick={handleAutoQuantityClick} className="m-1 bg-retrobg text-xs text-white p-2 rounded">
              Auto Quantity
            </button>
            {error2 && <p className="text-red-500 mt-2 text-sm">{error2}</p>}
            <div className="mt-2">
              {calculateResults.length > 0 && (
                <div>
                  <div className="text-sm text-retrobabypink">
                    <strong>You Need:</strong>
                  </div>
                  <div className="flex flex-wrap text-xs justify-center">
                    {calculateResults.map((result, index) => (
                      <div key={index} className="flex items-center mb-2">
                        <div className="flex flex-col items-center p-2 m-1 bg-retrobg rounded-lg">
                          <img src={result.image} alt={result.id} width="30" height="30" className="inline mr-2" />
                          <span>{result.name}</span>
                          <span>Amount: {result.totalQuantity}</span>
                          <span>MP: {result.mpValue.toLocaleString()} Coins</span>
                          <span>{result.craftCost ? `Craft Cost: ` + result.craftCost + ` Coins` : ""}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="text-xs text-center">
                    <i className="fa-solid fa-bolt" style={{ color: "#626e84" }} /> Energy: {(energyUsage * quantity).toLocaleString()}
                    {"  "}| <i className="fa-solid fa-clock" style={{ color: "#626e84" }} /> Time: {formatTime((time * quantity) / kits)}
                    {totalMarketPrice > 0 && (
                      <span>
                        {"  "}| <i className="fa-solid fa-dollar-sign" style={{ color: "#626e84" }} /> MP Price: {totalMarketPrice.toLocaleString()}
                      </span>
                    )}
                    {totalCraftPrice > 0 && totalCraftPrice !== totalMarketPrice && (<span>
                        {"  "}| <i className="fa-solid fa-dollar-sign" style={{ color: "#626e84" }} />Craft/MP: {totalCraftPrice.toLocaleString()}
                      </span>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>          
        </div>

        <div className="md:flex md:flex-row flex-col grid gap-2 w-full mt-2">
          {/*Farming Calculator*/}
          <div className="flex-1 bg-retrogray p-2 rounded-lg border-2 border-white">
            <h2 className="starterTitle2 text-center">Farming Calculator</h2>
            <h3 className="text-base text-center font-teko">Calculate how much you need to farm to reach a level</h3>
            <Select onChange={handleFarmItemChange} options={recipeDisplay} className="text-retropb text-sm" placeholder="Select a Crop..." />
            <div className="flex flex-col w-full md:w-[20%] md:mr-2 text-xs mt-1">
              <label className="mb-1"># of Soils:</label>
              <input
                type="number"
                placeholder="Set Soil Count"
                value={soilCount}
                onChange={(e) => setSoilCount(parseInt(e.target.value, 10))}
                min="1"
                className="h-8 p-2 w-full text-retrogray border border-gray-300 rounded"
              />
            </div>
            {error3 && <p className="text-red-500 mt-2 text-sm">{error3}</p>}
            <div className="mt-2">
              {calculatedQuantity > 0 && (
                <div className="text-center mt-4 text-sm">
                  <p>
                    <i className="fa-solid fa-hashtag" style={{ color: "#626e84" }} /> Seeds Needed: {calculatedQuantity.toLocaleString()} | {"  "}
                    <i className="fa-solid fa-dollar-sign" style={{ color: "#626e84" }} /> Seed Cost: {seedCost.toLocaleString()} Coins
                  </p>
                  <p>
                    <i className="fa-solid fa-clock" style={{ color: "#626e84" }} /> Grow Time: {formatTime(growTime / soilCount)}
                  </p>
                  <p>
                    <br />
                    <i className="fa-solid fa-bolt" style={{ color: "#626e84" }} />
                    Plant & Harvest Energy: {baseEnergy.toLocaleString()} | <i className="fa-solid fa-bolt" style={{ color: "#626e84" }} /> Watering Energy:{" "}
                    {waterEnergy.toLocaleString()}
                    <br />
                    <i className="fa-solid fa-bolt" style={{ color: "#626e84" }} /> Total Energy Needed: {totalEnergy.toLocaleString()}
                  </p>
                </div>
              )}
              {message3 && <p className="text-blue-500 mt-2 text-sm text-center">{message3}</p>}
            </div>
          </div>
          {/*Resource Calculator*/}
          <div className="flex-1 md:w-2/4 w-full bg-retrogray p-2 rounded-lg border-2 border-white">
            <h2 className="starterTitle2 text-center">Resource Calculator</h2>
            <h3 className="text-base text-center font-teko">Calculate how much you need to collect to reach a level</h3>
            <Select onChange={handleResourceChange} options={industryDisplay} className="text-retropb text-sm m-1" placeholder="Select an Industry..." />
            <div className="m-1 text-sm">
              <Select
                value={tierOpts.find((option) => option.value === resource)}
                onChange={handleTierChange}
                options={tierOpts}
                className="text-retropb"
                placeholder="Select Recipe.."
              />
            </div>
            {error4 && <p className="text-red-500 mt-2 text-sm">{error4}</p>}
            <div className="mt-2">
              {calculatedQuantity2 > 0 && (
                <div className="text-center mt-4 text-sm">
                  <p>
                    <i className="fa-solid fa-hashtag" style={{ color: "#626e84" }} /> Resource Amount Needed: {calculatedQuantity2.toLocaleString()}
                  </p>
                  <p>
                    <i className="fa-solid fa-bolt" style={{ color: "#626e84" }} /> Total Energy Required: {totalEnergy2.toLocaleString()}
                  </p>
                </div>
              )}
              {message4 && <p className="text-blue-500 mt-2 text-sm text-center">{message4}</p>}
            </div>
          </div>

          {/*Energy Calculator*/}
          <div className="flex-1 w-full md:max-w-2/4 md:w-2/4 bg-retrogray p-2 rounded-lg border-2 border-white">
            <h2 className="starterTitle2 text-center">Energy Calculator</h2>
            <h3 className="text-base text-center font-teko">Calculate the amount of an item is needed for energy</h3>
            <Select onChange={handleEnergyChange} options={energyDisplay} className="text-retropb text-sm m-1" placeholder="Select an Energy Item..." />
            <div className="m-1 text-sm flex-col">
              <label>How Much Energy Do you Need?</label>
              <input
                type="number"
                placeholder="Set Energy Amount"
                value={energyAmount}
                onChange={(e) => setEnergy(parseInt(e.target.value, 10))}
                min="1"
                className="h-8 p-2 w-full text-retrogray border border-gray-300 rounded"
              />
            </div>
            {error5 && <p className="text-red-500 mt-2 text-sm">{error5}</p>}
            <div className="mt-2">
              {calculatedQuantity3 > 0 && selectedEnergyItem && (
                <div className="text-center mt-4 text-sm">
                  <p className="mt-2">
                    <i className="fa-solid fa-hashtag" style={{ color: "#626e84" }} /> Resource Amount Needed: {calculatedQuantity3.toLocaleString()}
                  </p>
                  <p>
                    <i className="fa-solid fa-bolt" style={{ color: "#626e84" }} /> Total Energy Provided: {(selectedEnergyItem.energyValue * calculatedQuantity3).toLocaleString()}
                  </p>
                  <p>
                    <i className="fa-solid fa-dollar-sign" style={{ color: "#626e84" }} /> Total MP Cost:{" "}
                    {((selectedEnergyItem.marketPrice || 0) * calculatedQuantity3).toLocaleString()} Coins
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calculator;
