const categoryMapping = {
    blueprintsKitsRecipes: "Industry",
    interiorDecorations: "Decoration",
    exteriorDecorations: "Decoration",
    tools: "Tools",
    consumables: "Consumable",
    pets: "Pet Items",
    crafting: "Crafting",
    farming: "Farming",
    specialty: "Specialty",
    general: "General",
    "drink/food": "Consumable",
    industry: "Industry",
    utility: "Utility",
    wallpaper: "Wall Decor",
    bedcover: "Bedding",
    wearable: "Clothing",
    flooring: "Flooring",
    glowstick: "Glowstick",
    decoration: "Decoration",
    event: "Event Items",
    disc: "Discoverability",
    winery: "Winery",
    miscellaneous: "Misc",
    spacebbq: "Galactic Grill",
    stoneshaping: "Stoneshaping",
    landbbq: "Barney's BBQ",
    cooking: "Cooking",
    waterbbq: "Submarine Sizzler",
    woodwork: "Woodworking",
    metalworking: "Metalworking",
    machine: "The Machine",
    windmill: "Windmill",
    industries: "Not Craftable",
    textile_mill: "Textiler",
    shake: "Milkshake Machine",
    compost: "Composter",
    juicer: "Shorelime Juicer",
    coffee: "Latte Machine",
    quantum_recombinator: "Quantum Recombinator",
    moca: "Mocaverse Clubhouse",
    moca2: "Mocaverse Crafting Table",
    gemcutting: "Gem Cutting (Not available yet)",
  };

  const currencyMapping = {
    cur_coins: "Coins",
    cur_pixel: "$PIXEL",
    cur_cyber: "$CYBER",
    cur_nana: "$NANA",
    cur_pepe: "$PEPE",
    cur_other: "$Other",
    cur_polygon_berry: "Polygon Berry",
    cur_wron: "$WRON",
    cur_ape: "$APE",
  };

  const itemsToExclude = [
    "itm_yawnionSeeds",
    "itm_joybeanSeeds",
    "itm_baycSeeds",
    "itm_abomSeeds",
    "itm_perfectPopberrySeeds",
    "itm_mushroomSeeds_01",
    "itm_mushroomSeeds_02",
    "itm_mushroomSeeds_03",
    "itm_goldenPopberrySeeds",
    "itm_60dayflower_seed",
    "ach_sluggery",
    "ach_slughutch_kit_02",
    "ach_slughutch_kit_03",
    "ach_slughutch_kit_04",
    "ach_slughutch_kit_05",
    "ach_pet_treats_12pack",
    "ach_Popberry_Pie_TEST",
    "ach_watertest",
    "ach_Bomb-Shell",
  ];

  const industryMap = {
    winery: "Winery",
    miscellaneous: "Misc",
    spacebbq: "Galactic Grill",
    stoneshaping: "Stoneshaping",
    landbbq: "Barney's BBQ",
    cooking: "Cooking",
    waterbbq: "Submarine Sizzler",
    woodwork: "Woodworking",
    metalworking: "Metalworking",
    machine: "The Machine",
    windmill: "Windmill",
    farming: "Windmill",
    industries: "Not Craftable",
    textile_mill: "Textiler",
    shake: "Milkshake Machine",
    compost: "Composter",
    juicer: "Shorelime Juicer",
    coffee: "Latte Machine",
    quantum_recombinator: "Quantum Recombinator",
    moca: "Mocaverse Clubhouse",
    moca2: "Mocaverse Crafting Table",
    gemcutting: "Gem Cutting (Not available yet)",
    cookboxing: "Cooking Boxes" ,
    mineboxing: "Mining Boxes" ,
    businessboxing: "Business Boxes" ,
    metalboxing: "Metalworking Boxes",
    stoneboxing: "Stoneshaping Boxes",
    forestboxing: "Forestry Boxes",
    animalboxing: "Animal Care Boxes",
    farmboxing: "Farming Boxes",
    woodboxing: "Woodworking Boxes",
  };

  const nonCraftableItemIds = [
    "itm_Bomb_Shell",
    "itm_runningShoe_basic",
    "itm_silkslughutchblueprints",
    "itm_textilemillblueprints",
    "itm_slughutch_kit_05",
    "itm_slughutch_kit_04",
    "itm_slughutch_kit_03",
    "itm_slughutch_kit_02",
    "itm_gem_01",
  ];

  export { categoryMapping, currencyMapping, itemsToExclude, industryMap, nonCraftableItemIds };
