import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import teacher from "../assets/images/teaching-Pixelore.png";
import "@fortawesome/fontawesome-free/css/all.min.css";
import BackToTopGlobal from '../components/BackToTop2';

const Pets = () => {
  const [selectedCategory, setSelectedCategory] = useState("About");
  const navigate = useNavigate();

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    navigate(`#${category.toLowerCase()}`);
  };

  useEffect(() => {
    const hash = window.location.hash.substring(1);
    if (hash) {
      setSelectedCategory(hash.charAt(0).toUpperCase() + hash.slice(1));
    }
  }, []);

  return (
    <div className="container mx-auto mt-5 p-6 rounded-lg shadow-lg bg-retrodp">
      <h1 className="text-6xl font-heading mb-6 text-center text-retroegg">Pet Guides</h1>
      <div className="flex justify-center items-start min-h-fit">
        {["About", "Hatching", "Types"].map((category) => (
          <button
            key={category}
            className={`py-2 px-4 m-2 rounded-full ${selectedCategory === category ? "bg-retropurple text-white" : "bg-retrosilver text-gray-700"}`}
            onClick={() => handleCategoryClick(category)}>
            {category}
          </button>
        ))}
      </div>

      <div className="guide-content space-y-4">
        {selectedCategory === "About" && (
          <section id="About" className="mb-6">
            <div className="bg-retropb rounded-md p-2 mt-2">
            <h2 className="starterTitle">What are Pets</h2>
            <p className="starterText">
              Pets are one of the NFTs made for the Pixels Platform. Pets follow around Pixels players, require attention in game, can be fed, given water to, played with, and give
              some extra perks to holders.
              <br />
              You can see more about those perks under{" "}
              <a className="text-retropink" href="#types">
                Types
              </a>
            </p></div>

            <div className="bg-retropb rounded-md p-2 mt-2">
            <h2 className="starterTitle">How do I get one?</h2>
            <p className="starterText">
              Right now you can't. There are no current mints, but you can still purchase them from other players. However, if you get a hatched pet, you cant change the stats. So
              be sure to check out stats before purchasing. A capsule can still be influenced.
            </p></div>

            <div className="bg-retropb rounded-md p-2 mt-2">
            <h2 className="starterTitle">How often is Minting?</h2>
            <p className="starterText">
              The team hinted at doing one mint a month, but so far there has only been 1 public mint, 1 raffle mint (carnival), and some smaller giveaways. There hasn't really
              been a 2nd public mint.
            </p></div>

            <div className="bg-retropb rounded-md p-2 mt-2">
            <h2 className="starterTitle">How to Care for a Pet</h2>
            <p className="starterText">
              Pets need attention to be happy c(ꐦㅍ _ㅍ)o sometimes too much...
              <br />
              There's a tracker for daily watering and daily feeding and I'll get into the details of that at the end of this.... IT'S COMPLEX, but there's more you can do!
              <br />
              <br />
              <b>What can you do with your pet?</b>
              <br />
              🩷If it's tired and needs rest, you can give it an <b>Energy Drink</b> or set it to rest (Rest Pet)
              <br />
              🩷If it's hungry, you can give it <b>Pet Treats</b> or <b>Popberries</b>
              <br />
              🩷If it's thirsty, you can water your pet with the <b>Rusty Watering Can</b>
              <br />
              🩷If it wants to play, you can give it a <b>Stick</b> or if it's just bored, you can leave him out and walk around for a bit!
              <br />
              💔<b>Grainbows</b> make them vomit. So does overfeeding.
              <br />
              <br />
              So here's the fun tracker part for your watering and feeding streak:
              <br />
              💫 To keep your streak going, you must water/feed after 24 hours has passed since the previous tracked feeding but before 48 hours has passed
              <br />
              💫 If you do not feed or water your pet within 24 hours, you will forfeit 1 day on your streak
              <br />
              💫 If you do not feed or water your pet within 48 hours, you will forfeit your streak
              <br />
              <br />
              Knowing the rules makes it much easier to keep track of your streak. But it can still be really confusing.... <br />
              So I'll give an example..
              <img
                className="hidden md:inline md:border md:border-solid md:border-retrogray md:border-4 md:rounded"
                src={teacher}
                alt="teachingPixelore"
                width="200"
                height="500"
                align="right"
              />{" "}
              <br />
              ⊳ Let's say you feed your pet for the first time today Saturday at 10 AM. +1 Day On Feeding
              <br />
              📝 No other feeding will count before 10 AM on Day 2.
              <br />
              ⊳ Day 2, you can feed your pet again at 10AM tomorrow Sunday all the way until Monday 10AM for it to add +1 to the streak on the given day
              <br />
              📝 If you forget to feed the pet until 10 AM Monday, the streak will be added on Monday so you will have missed Sunday
              <br />
              📝 If you forget to feed the pet between 10 AM Monday and 10 AM Tuesday, the streak will be lost completely.
              <br />
              ⊳ Your Day 3 streak time will now be at the new time that you've fed the pet within the 48 hour timeframe. So if you fed your pet at 4PM, Day 3 will not set until
              4PM.
              <br />
              <br />
              Here's a more visible time frame of reasonable feeding schedules:
              <br />
              ⊳ Saturday 10AM
              <br />
              ⊳ Sunday 10 AM <br />
              ⊳ Monday 1PM [set new feeding time]
              <br />
              ⊳ Tuesday 1PM
              <br />
              ⊳ Thursday 10 AM [missed 1 day and set new feeding time]
              <br />⊳ <span className="text-red-500">Thursday 2 PM [Does nothing to the schedule]</span>
              <br />⊳ <span className="text-red-500">Friday 9 AM [WRONG and can cause missed day if you don't feed again for the day]</span>
              <br />
              ⊳ Saturday 3PM [new feeding time]
              <br />
              <br />
              I hope this makes it clearer for someone ╰(*°▽°*)╯
              <br />
              Pro Tip: Keep track of when the counter changes on your pet's streaks, and use that time as a starting spot if you don't remember what time you fed/watered
            </p></div>
            <div className="bg-retropb rounded-md p-2 mt-2">
            <h2 className="starterTitle">Pet Happiness</h2>
            <p className="starterText">
              Here's some basic information that I have seen about pet happiness:
              <br />
              ⬆️ 75% Increase if Pet is at 0% and is hungry/thirsty and just came out of resting <br />
              ⬆️ 25% Increase keeping Pet around after it has been resting and has been fed/watered, within 36 minutes <br />
              ⬆️ 30% Increase if Pet is Hungry + Eats a Pet Treat
              <br />
              ⬆️ 11% Increase if Pet is Hungry + Eats a Popberry
              <br />
              ⬆️ 24% Increase if Pet is Thirsty + Drinks Water
              <br />
              ⬇️ Gradual slow increase when you keep pet around when it misses you (I have witnessed around 2% per 2 minutes)
              <br />
              ⬇️ Happiness decreases by 15% when you rest your pet (not always, just the first time after a while)
              <br />
              <br />
              Something that is kinda harder to track is the amount of pet % increase/decrease for when the pet is happy to be around, or when the pet wants rest.
            </p></div>
          </section>
        )}

        {selectedCategory === "Hatching" && (
          <section id="hatching" className="mb-6">
            <div className="bg-retropb rounded-md p-2 mt-2">
            <h2 className="starterTitle">Potion Types</h2>
            <h6 className="text-retropink text-center">Please know that this is just my own research and I could be incorrect about some of this information. </h6>
            <p className="starterText">
              <i className="fa-solid fa-flask" style={{ color: "#bf0d0d" }} /> Speed Potions - Chance to Increase Speed In Pets
              <br />
              <i className="fa-solid fa-flask" style={{ color: "#6705a8" }} /> Strength Potions - Chance to Increase Strength in Pets
              <br />
              <i className="fa-solid fa-flask" style={{ color: "#38a3e5" }} /> Luck Potions - Chance to Increase Luck, Speed and/or Strength in Pets
              <br />
              <br />
              <i className="fa-solid fa-flask" style={{ color: "#a5afb6" }} /> Jumbo - Best Chance | <i className="fa-solid fa-flask" style={{ color: "#a5afb6" }} /> Large - Good Chance |{" "}
              <i className="fa-solid fa-flask" style={{ color: "#a5afb6" }} /> Medium - Low Chance | <i className="fa-solid fa-flask" style={{ color: "#a5afb6" }} /> Small - Lowest Chance
              <br /><br />
              It's best to use Jumbo Potions to get the highest stat chances for your pet, but if you cannot afford Jumbos, then try to use Large Potions. Small Potions should be a
              last resort.<br/><br />
              Best Tip passed around on the web are to use 26 of your desired stat potion (for example 26 strength, 26 speed, 26 Luck) and then 4 of another stat. There's still a luck factor but this is one of the highest probability options.
            </p></div>

            <div className="bg-retropb rounded-md p-2 mt-2">
            <h2 className="starterTitle">Potion Recipes</h2>
            <div className="starterText">
              You can make potions on a potion table, which are a limited industry. You need 30 potions to hatch a pet, and they each take 3 minutes and 15 seconds to make (45s to
              add ingredients, 2m30s for the potion to brew). Every item you add also takes 0.1 Energy.
              <br />
              The below recipes are taken from other places, and some I've tried myself but there's never a guarantee ƪ(˘⌣˘)ʃ Some are more likely than others
              <table className="table1">
                <thead>
                  <tr>
                    <th></th>
                    <th>Potion</th>
                    <th>Size</th>
                    <th>Recipes</th>
                  </tr>
                </thead>
                <tbody>
                <tr>
                  <td>
                    <i className="fa-solid fa-flask" style={{ color: "#bf0d0d" }} />
                  </td>
                  <td>Speed</td>
                  <td>Jumbo</td>
                  <td>
                  <i class="fa-solid fa-tag fa-xs" style={{color: "#caa0bb"}}/>15 Wax + 1 Cooking Mix
                    <br />
                    <i class="fa-solid fa-tag fa-xs" style={{color: "#caa0bb"}}/>14 Wax + 1 Cooking Mix + 1 Slime
                    <br />
                    <i class="fa-solid fa-tag fa-xs" style={{color: "#caa0bb"}}/>20 Wax + 1 Cooking mix/Honey
                    <br /><i class="fa-solid fa-tag fa-xs" style={{color: "#caa0bb"}}/>1 Cooking Mix + 10 Slime + 10 Wax
                  </td>
                </tr>
                <tr>
                  <td>
                    <i className="fa-solid fa-flask" style={{ color: "#bf0d0d" }} />
                  </td>
                  <td>Speed</td>
                  <td>Large</td>
                  <td><i class="fa-solid fa-tag fa-xs" style={{color: "#caa0bb"}}/>10 Wax + Cooking Mix *maybe</td>
                </tr>
                <tr>
                  <td>
                    <i className="fa-solid fa-flask" style={{ color: "#bf0d0d" }} />
                  </td>
                  <td>Speed</td>
                  <td>Medium</td>
                  <td>
                  <i class="fa-solid fa-tag fa-xs" style={{color: "#caa0bb"}}/>5 Cooking Mix + 1 Egg
                    <br />
                    <i class="fa-solid fa-tag fa-xs" style={{color: "#caa0bb"}}/>20 Crop + Cooking Mix
                  </td>
                </tr>
                <tr>
                  <td>
                    <i className="fa-solid fa-flask" style={{ color: "#bf0d0d" }} />
                  </td>
                  <td>Speed</td>
                  <td>Small</td>
                  <td>
                  <i class="fa-solid fa-tag fa-xs" style={{color: "#caa0bb"}}/>3 Voidtonium
                    <br />
                    <i class="fa-solid fa-tag fa-xs" style={{color: "#caa0bb"}}/>20 low lvl crop + 1 Cooking Mix
                    <br /><i class="fa-solid fa-tag fa-xs" style={{color: "#caa0bb"}}/>1 Grumpkin + 3 Honey{" "}
                  </td>
                </tr>
                <tr>
                  <td>
                    <i className="fa-solid fa-flask" style={{ color: "#6705a8" }} />
                  </td>
                  <td>Strength</td>
                  <td>Jumbo</td>
                  <td><i class="fa-solid fa-tag fa-xs" style={{color: "#caa0bb"}}/>15 Cooking Mix</td>
                </tr>
                <tr>
                  <td>
                    <i className="fa-solid fa-flask" style={{ color: "#6705a8" }} />
                  </td>
                  <td>Strength</td>
                  <td>Large</td>
                  <td><i class="fa-solid fa-tag fa-xs" style={{color: "#caa0bb"}}/>10 Cooking Mix + Slime + 1 Crop</td>
                </tr>
                <tr>
                  <td>
                    <i className="fa-solid fa-flask" style={{ color: "#6705a8" }} />
                  </td>
                  <td>Strength</td>
                  <td>Medium</td>
                  <td>
                  <i class="fa-solid fa-tag fa-xs" style={{color: "#caa0bb"}}/>5 Honey + 1 Cooking Mix
                    <br />
                    <i class="fa-solid fa-tag fa-xs" style={{color: "#caa0bb"}}/>10 Cooking Mix + 1 Honey
                    <br /><i class="fa-solid fa-tag fa-xs" style={{color: "#caa0bb"}}/>6 Wax + 10 Honey{" "}
                  </td>
                </tr>
                <tr>
                  <td>
                    <i className="fa-solid fa-flask" style={{ color: "#6705a8" }} />
                  </td>
                  <td>Strength</td>
                  <td>Small</td>
                  <td>
                  <i class="fa-solid fa-tag fa-xs" style={{color: "#caa0bb"}}/>5 Honey
                    <br />
                    <i class="fa-solid fa-tag fa-xs" style={{color: "#caa0bb"}}/>10 Honey
                  </td>
                </tr>
                <tr>
                  <td>
                    <i className="fa-solid fa-flask" style={{ color: "#38a3e5" }} />
                  </td>
                  <td>Luck</td>
                  <td>Jumbo</td>
                  <td>
                  <i class="fa-solid fa-tag fa-xs" style={{color: "#caa0bb"}}/>20 Wax + 1 Egg
                    <br />
                    <i class="fa-solid fa-tag fa-xs" style={{color: "#caa0bb"}}/>10 Slime
                    <br />
                    <i class="fa-solid fa-tag fa-xs" style={{color: "#caa0bb"}}/>15 Wax
                  </td>
                </tr>
                <tr>
                  <td>
                    <i className="fa-solid fa-flask" style={{ color: "#38a3e5" }} />
                  </td>
                  <td>Luck</td>
                  <td>Large</td>
                  <td>
                  <i class="fa-solid fa-tag fa-xs" style={{color: "#caa0bb"}}/>10 Slime
                    <br />
                    <i class="fa-solid fa-tag fa-xs" style={{color: "#caa0bb"}}/>10 Wax + Cooking Mix
                    <br /><i class="fa-solid fa-tag fa-xs" style={{color: "#caa0bb"}}/>7 Wax + 5 Cooking Mix + 5 Eggs + 6 Honey{" "}
                  </td>
                </tr>
                <tr>
                  <td>
                    <i className="fa-solid fa-flask" style={{ color: "#38a3e5" }} />
                  </td>
                  <td>Luck</td>
                  <td>Medium</td>
                  <td>
                  <i class="fa-solid fa-tag fa-xs" style={{color: "#caa0bb"}}/>2 Cooking Mix
                    <br /><i class="fa-solid fa-tag fa-xs" style={{color: "#caa0bb"}}/>5 Cooking Mix + 1 Honey
                    <br /><i class="fa-solid fa-tag fa-xs" style={{color: "#caa0bb"}}/>5 Wax
                    <br /><i class="fa-solid fa-tag fa-xs" style={{color: "#caa0bb"}}/>3 Cooking Mix
                  </td>
                </tr>
                <tr>
                  <td>
                    <i className="fa-solid fa-flask" style={{ color: "#38a3e5" }} />
                  </td>
                  <td>Luck</td>
                  <td>Small</td>
                  <td>
                  <i class="fa-solid fa-tag fa-xs" style={{color: "#caa0bb"}}/>1 of Any Crop/Material
                    <br /><i class="fa-solid fa-tag fa-xs" style={{color: "#caa0bb"}}/>1 Cooking Mix
                  </td>
                </tr></tbody>
              </table>
            </div></div>

            <div className="bg-retropb rounded-md p-2 mt-2">
            <h2 className="starterTitle">Hatching Steps</h2>
            <p className="starterText">
              <strong>Things you'll need:</strong>
              <br />
              A Growth Lab, 30 Potions and a Pet Capsule.
              <br />
              Growth Labs can be purchased from the Pet Store
              <br />
              <br />
              If you don't have a pet capsule, you can add potions to the growth lab, but there will be nothing to hatch.
              <br />
              You can also set the potions before you decide on a capsule, and have them ready beforehand, but if you move the growth lab, you'll lose the potions.
              <br />
              <br />
              <strong>What To Do:</strong>
              <br />
              Place the Growth Lab on a Speck or NFT Land that you own - if you're only a manager, it wont work. Start placing in the 30 Potions. Each one takes time to add (1
              minute) so 30 minutes total to add all the potions.
              <br />
              When all 30 Potions have been added, you can select your pet capsule that you want to hatch and a new pet shall be born!
            </p>
            </div>
          </section>
        )}

        {selectedCategory === "Types" && (
          <section id="types" className="mb-6">
            <div className="bg-retropb rounded-md p-2 mt-2">
            <h2 className="starterTitle">Pet Attributes</h2>
            <div className="starterText">
              Pets were added to the Pixels game with very little utility. There isn't much use for them, but they're not completely useless. There are some traits on a pet that
              provide boosts to general gameplay. I'll list the attributes below that are on each pet, and then I'll provide more information for each one based on observation and general user findings.
              <br/>Pet Names can be duplicated and there's no way to change the names ( •̀ ω •́ )✧
              
              <div className="overflow-auto text-sm">
              <table className="table1">
                <thead>
                  <tr>
                    <th>Attribute</th>
                    <th>
                      Attribute
                      <br />
                      Type
                    </th>
                    <th>Usability</th>
                    <th>Other Notes</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Luck</td>
                    <td>Stats</td>
                    <td>None</td>
                    <td align="center">-</td>
                  </tr>
                  <tr>
                    <td>Smarts</td>
                    <td>Stats</td>
                    <td>Multiplier for other stats</td>
                    <td>See below in Special pet stats for more info!</td>
                  </tr>
                  <tr>
                    <td>Speed</td>
                    <td>Stats</td>
                    <td>
                      Player Range - Pet must be happy for it to work
                      <br />
                      Also the range downwards/south is -1 <br/>(1 less tile reach when reaching downwards)
                    </td>
                    <td>
                      <h6 className="font-ubuntu text-lg text-retrobabypink">Thank you P2G Guild for helping with this information</h6>Without Pet - 3 Tile Reach
                      <br />1 - 19 Speed - 4 Tile Reach
                      <br />
                      20 - 59 Speed - 5 Tile Reach
                      <br />
                      60 - 89 Speed - 6 Tile Reach
                      <br />
                      90 - 100 Speed - 7 Tile Reach
                    </td>
                  </tr>
                  <tr>
                    <td>Strength</td>
                    <td>Stats</td>
                    <td>Extra Storage</td>
                    <td>
                      0 - 39 Strength = 3 Storage Slots
                      <br />
                      40 - 49 Strength = 4 Storage Slots
                      <br />
                      50 - 59 Strength = 5 Storage Slots
                      <br />
                      60 - 69 Strength = 6 Storage Slots
                      <br />
                      70 - 79 Strength = 7 Storage Slots
                      <br />
                      80 - 89 Strength = 8 Storage Slots
                      <br />
                      90 - 99 Strength = 9 Storage Slots
                      <br />
                      100 Strength = 12 Storage Slots
                    </td>
                  </tr>
                  <tr>
                    <td>Adornment</td>
                    <td>Visual</td>
                    <td>None</td>
                    <td align="center">-</td>
                  </tr>
                  <tr>
                    <td>Adornment Color</td>
                    <td>Visual</td>
                    <td>None</td>
                    <td align="center">-</td>
                  </tr>
                  <tr>
                    <td>Body</td>
                    <td>Visual</td>
                    <td>None</td>
                    <td>Check out the Pet Types below to see how/when these were available.</td>
                  </tr>
                  <tr>
                    <td>Body Color</td>
                    <td>Visual</td>
                    <td>None</td>
                    <td align="center">-</td>
                  </tr>
                  <tr>
                    <td>Eye Color</td>
                    <td>Visual</td>
                    <td>None</td>
                    <td align="center">-</td>
                  </tr>
                  <tr>
                    <td>Eyes</td>
                    <td>Visual</td>
                    <td>None</td>
                    <td align="center">-</td>
                  </tr>
                  <tr>
                    <td>Head</td>
                    <td>Visual</td>
                    <td>None</td>
                    <td align="center">-</td>
                  </tr>
                  <tr>
                    <td>Head Color</td>
                    <td>Visual</td>
                    <td>None</td>
                    <td align="center">-</td>
                  </tr>
                  <tr>
                    <td>Mouth</td>
                    <td>Visual</td>
                    <td>None</td>
                    <td align="center">-</td>
                  </tr>
                  <tr>
                    <td>Stage</td>
                    <td>Visual</td>
                    <td>None</td>
                    <td>
                      There are only two stages - Capsule & Mature.
                      <br />
                      Capsule means it hasn't been hatched yet and you can still determine stat traits with potions.
                      <br />
                      Capsules have pre-determined Visual Traits.
                    </td>
                  </tr>
                  <tr>
                    <td>Tail</td>
                    <td>Visual</td>
                    <td>None</td>
                    <td align="center">-</td>
                  </tr>
                  <tr>
                    <td>Tail Color</td>
                    <td>Visual</td>
                    <td>None</td>
                    <td align="center">-</td>
                  </tr>
                  <tr>
                    <td>Terrain</td>
                    <td>Visual</td>
                    <td>None</td>
                    <td align="center">-</td>
                  </tr>
                </tbody>
              </table></div>
            </div></div>

            <div className="bg-retropb rounded-md p-2 mt-2">
              <h2 className="starterTitle">Pet Types & Stats</h2>
              <div className="starterText">
                <p className="text-xs">Without official information, it's so difficult to find this information reliably. I got some of this from the pet guides article on Pixels Help Desk and the rest from research.<br/>
                Some Pets Have Special Stat Boosts for their "Smarts" and this is the base multiplier for other stats when hatching<br/>
                Essentially each pet has a base quality, and the smarts determines how much to multiply the potion stats by<br/>
                Speed, Strength, Luck will be abbreviated as SSL in the information below</p>
                <br/>
                <div className="overflow-auto text-sm">
                <table className="table1"><thead><tr><th>Pet Type</th><th>Amount</th><th>Smart</th><th>Base SSL</th><th>Token Numbers</th><th>More Details</th></tr></thead>
                <tbody>
                <tr><td>🦎 Coingecko</td><td>2</td><td>50</td><td>19.53125</td><td>#4, #5<br/>#3276 - #3290</td><td>2 Coingecko pets were given away in December 2023 as part of collaboration with their team.<br/>15 Coingecko Pets were given away in April 2024 as part of a collaboration event with Coingecko.</td></tr>
                <tr><td>🐶 Sully</td><td>2</td><td>50</td><td>19.53125</td><td>#3</td><td>1/1 Pet rewarded to a Twitter Giveaway Winner for a "Pet Submission Contest" where the winner would have their pet transformed to a Pixel Pet</td></tr>
                <tr><td>🦖 Genesis Pets</td><td>2</td><td>50</td><td>19.53125</td><td>#2, #6 - #203<br/>#206 - #253 (Team Tokens)</td><td>200 Genesis Pets were given away in the Pixels Carnival Event in November 2023.<br/>50 more Genesis Pets were generated for giveaways and team</td></tr>
                <tr><td>🦄 Genesis Pets</td><td>2</td><td>50</td><td>19.53125</td><td>#1 & #204 & #205</td><td>3 of the 250 Genesis Pets mentioned above are Rainbow Horses</td></tr>
                <tr><td>🍡 Moca</td><td>2</td><td>40</td><td>15.625</td><td>#254 - #258</td><td>5 Moca Pets were given away as part of a collaboration event with Moca for the Mocaverse Event in December/January 2024.</td></tr>
                <tr><td>🐉 Dragon</td><td>2</td><td>40</td><td>15.625</td><td>#259 - #265</td><td>7 Lunar New Year Dragon pets were given away for the Chinese New Year in February 2024.</td></tr>
                <tr><td>🐕 Doggos</td><td>2</td><td>25</td><td>9.765625</td><td>#266 - #3275</td><td> 3010 Pet Capsules could be minted for 500 $PIXEL each on February 27th 2024.<br/>The First doggo (266) has stats like a special pet instead of a doggo</td></tr>
                <tr><td>🦊 Fokx </td><td>2</td><td>40</td><td>15.625</td><td>#3291 - #3295</td><td>5 OKX (Fokx) Pets given away as part of a collaboration event in August 2024.</td></tr>
                <tr><td>🐄 Cows</td><td>2</td><td>25</td><td>9.765625</td><td>#3296 - #3595</td><td>300 Cow Pets for the first Barney's Bazaarn Event</td></tr>
                </tbody></table> </div>           
                <br/>
                Total Pet Count should be: <strong>3,595</strong>
                <p>You need <b>30 potions</b> to hatch a pet - these 30 Potions determine Strength, Luck and Speed. But each potion has a Point on it. <u>Jumbo potions have the best points.</u><br/>
                I don't have the exact number of points for each potion (As soon as I find the math to it, I'll add that here)<br/>
                But the smarts multiplier determines how high of a stat you can acquire based on the potions applied. Of course there's still RNG<br/></p>
              </div>
            </div>
          </section>
        )}
      </div>
      <BackToTopGlobal/>
    </div>
  );
};

export default Pets;
