import React, { useEffect, useState } from "react";
import axios from "axios";
import GIF from "gif.js.optimized";
import { avatarBaseUrl } from "../endpointData";
import LoadingSpinner from "../components/LoadingSpinner";

const Avatars = () => {
  const [avatars, setAvatars] = useState([]);
  const [selectedAvatar, setSelectedAvatar] = useState("");
  const [tokenNum, setTokenNum] = useState("");
  const [error, setError] = useState("");
  const [gifs, setGifs] = useState({});
  const [combinedImageUrl, setCombinedImageUrl] = useState("");
  const [avatarInfo, setAvatarInfo] = useState("");
  const [blockchainFilter, setBlockchainFilter] = useState("all");
  const [loading, setLoading] = useState(false);
  const [refetching, setRefetch] = useState(false);

  useEffect(() => {
    const fetchAvatars = async () => {
      try {
        const response = await axios.get("https://api.pixelore.wiki/api/avatars");
        setAvatars(response.data);
      } catch (err) {
        setError("Error fetching avatars");
        console.error(err);
      }
    };

    fetchAvatars();
  }, []);

  const recentAvatars = avatars.slice(-3);
  const avatarNames = recentAvatars.map((avatar) => avatar.customName || avatar.id).join(", ");

  const handleFilterChange = (e) => {
    setBlockchainFilter(e.target.value);
  };

  const handleDropdownChange = (e) => {
    setSelectedAvatar(e.target.value);
  };

  const handleGenerateClick = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    if (!selectedAvatar || !tokenNum) {
      setError("Please select an avatar and enter a token number");
      return;
    }

    setLoading(true);
    setAvatarInfo("");
    setGifs({});
    setCombinedImageUrl("");
    setError("");
    setRefetch(false);

    try {
      const avatar = avatars.find((av) => av._id === selectedAvatar);

      if (avatar.chain === "ronin") {
        await fetchRoninTraits(avatar, tokenNum);
      } else {
        await fetchOpenSeaTraits(avatar, tokenNum);
      }
    } catch (err) {
      setError("Couldn't get that avatar's traits.");
      setLoading(false);
      console.error(err);
    }
  };

  const shortenAddress = (address) => {
    if (address.length <= 10) return address;
    return `${address.substring(0, 5)}...${address.substring(address.length - 5)}`;
  };

  const fetchRoninTraits = async (avatar, tokenNum) => {
    let searchContract = avatar.contractAddress;
    let traits = {};

    try {
      try {
        const response = await fetch("https://api.pixelore.wiki/api/ronintraits", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ searchContract, tokenNum }),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        const nftInfo = data.nftInfo;

        try {
          const owner = nftInfo.owner ? nftInfo.owner : "N/A";
          const contract = avatar.contractAddress || "N/A";
          const nftImage = nftInfo.cdnImage || "";
          const network = avatar.chain;
          const shortenedContract = shortenAddress(contract);
          const shortenedOwner = shortenAddress(owner);

          const combinedAvatarInfo = `<strong>Chain:</strong> ${network}<br><strong>Contract Address:</strong> <a className="myLinks" target="_blank" rel="noreferrer" href="https://marketplace.skymavis.com/collections/${contract}/${tokenNum}">${shortenedContract}</a><br><strong>Current Owner:</strong> <a  target="_blank" rel="noreferrer" href="https://marketplace.skymavis.com/account/${owner}">${shortenedOwner}</a><br><img src="${nftImage}" height="150" width="150"/>`;
          setAvatarInfo(combinedAvatarInfo);
          const attributes = nftInfo.attributes;
          // Convert the attributes object to the desired traits format
          traits = Object.keys(attributes).reduce((acc, key) => {
            const value = attributes[key][0]; // Assuming there's only one value per key
            acc[
              key
                .toLowerCase()
                .replace(/[^\w\s]+/g, "")
                .replace(/\s+/g, "")
            ] = value
              .toLowerCase()
              .replace(/[^\w\s]+/g, "")
              .replace(/\s+/g, "");
            return acc;
          }, {});
        } catch (error) {
          console.log("No Data to Display for this NFT: ", error);
          const combinedAvatarInfo = `No data to display for this NFT`;
          setAvatarInfo(combinedAvatarInfo);
        }
      } catch (error) {
        console.log("No Ronin Traits needed for this NFT: ", error);
      }

      const specialTraits = specialTraitMappings[avatar.id] || {};
      Object.keys(specialTraits).forEach((key) => {
        traits[key] = specialTraits[key]; // Add or update the trait
      });

      const imageUrls = getImageUrls(avatar, traits);
      await combineAndDisplaySpritesheets(imageUrls, avatar, traits);
    } catch (err) {
      setLoading(false);
      console.error("Error fetching Ronin traits:", err);
      setError("Couldn't find traits for that token ID on Ronin. Are you sure that's correct?");
    }
  };

  const fetchOpenSeaTraits = async (avatar, tokenNum) => {
    let searchChain = avatar.chain;
    let searchContract = avatar.contractAddress;
    let traits = {};

    try {
      try {
        const response = await fetch(`https://api.pixelore.wiki/api/openseatraits?chain=${searchChain}&contractAddress=${searchContract}&tokenNum=${tokenNum}`);

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        const nftInfo = data.nft;

        let owner, ownerUrl, description;
        if (nftInfo.owners && nftInfo.owners.length > 0) {
          owner = nftInfo.owners[0].address.toLowerCase();
          ownerUrl = `https://opensea.io/${owner}`;
          description = nftInfo.description;
        } else {
          owner = "N/A";
          ownerUrl = "none";
          description = "No description from this one ( ｡ •̀ ᴖ •́ ｡) Y NO DESCRIPTION";
        }

        const network = avatar.chain;
        const contract = avatar.contractAddress.toLowerCase() || "N/A";
        const nftImage = nftInfo.display_image_url || "";
        const shortenedContract = shortenAddress(contract);
        const shortenedOwner = shortenAddress(owner);

        const combinedAvatarInfo = `<strong>Chain:</strong> ${network}<br><strong>Contract Address:</strong> <a className="myLinks" href="https://opensea.io/assets/ethereum/${contract}/${tokenNum}" target="_blank" rel="noreferrer">${shortenedContract}</a><br><strong>Current Owner:</strong> <a href="${ownerUrl}" target="_blank" rel="noreferrer">${shortenedOwner}</a><br><strong>NFT Description: </strong>${description}<br><img src="${nftImage}" height="100" width="100"/>`;
        setAvatarInfo(combinedAvatarInfo);

        const traitsArray = nftInfo.traits || []; // Build traits object

        traits = traitsArray.reduce((acc, trait) => {
          if (trait.value) {
            const value =
              typeof trait.value === "string"
                ? trait.value
                    .toLowerCase()
                    .replace(/[^a-z0-9]+/g, "")
                    .replace(/\s+/g, "")
                : String(trait.value)
                    .toLowerCase()
                    .replace(/[^a-z0-9]+/g, "")
                    .replace(/\s+/g, "");
            const traitType = trait.trait_type
              .toLowerCase()
              .replace(/[^a-z0-9]+/g, "")
              .replace(/\s+/g, "");
            acc[traitType] = value;
          }
          return acc;
        }, {});
      } catch (error) {
        console.log("No Data to Display for this NFT: ", error);
        const combinedAvatarInfo = `No data to display for this NFT`;
        setAvatarInfo(combinedAvatarInfo);
      }

      const specialTraits = specialTraitMappings[avatar.id] || {};
      Object.assign(traits, specialTraits);

      let imageUrls = getImageUrls(avatar, traits);
      if (imageUrls.length > 0) {
        await combineAndDisplaySpritesheets(imageUrls, avatar, traits);
      } else {
        imageUrls = refetchImagesWithDefault(avatar, traits);
        if (imageUrls.length > 0) {
          await combineAndDisplaySpritesheets(imageUrls, avatar, traits);
        } else {
          console.warn("No image URLs found for the given traits.");
          setError("Couldn't load images from Pixels, sorry.");
        }
      }
    } catch (err) {
      setLoading(false);
      console.error("Error fetching OpenSea traits:", err);
      setError("Couldn't find traits for that token ID on OpenSea. Are you sure that's correct?");
    }
  };

  const refetchImagesWithDefault = (avatar, traits) => {
    try {
      setRefetch(true);
      const defaultTraits = defaultTraitMapping[avatar.id] || {};
      Object.assign(traits, defaultTraits);
      const imageUrls = getImageUrls(avatar, traits);
      return imageUrls;
    } catch (error) {
      console.log("Failed to Refetch traits: ", error);
      setLoading(false);
    }
  };

  const specialTraitMappings = {
    angrydynomitesfire: {
      full: "default",
    },
    angrydynomiteswater: {
      full: "default",
    },
    arteezzykool: {
      arteezzykool: "arteezzykool",
    },
    chimpers: {
      full: tokenNum,
    },
    chimpersgenesis: {
      full: tokenNum,
    },
    chimperstraining: {
      tokenid: tokenNum,
    },
    cryptomasks: {
      body: "body",
    },
    deadfellaz: {
      tokenid: tokenNum,
    },
    dizzy: {
      fold: "default",
    },
    ethlizardlocked: {
      tokenid: tokenNum,
    },
    ethlizardventure: {
      tokenid: tokenNum,
    },
    ethlizardgenesis: {
      tokenid: tokenNum,
    },
    falarmonkey: {
      falarmonkey: "falarmonkey",
    },
    fightleague: {
      fightleague: "fightleague",
    },
    foxfam: {
      "1of1": tokenNum,
    },
    galacticapes: {
      avatar: tokenNum,
    },
    gam3s: {
      gam3s: "gam3s",
    },
    glizzygang: {
      base: "mainglizzy",
    },
    jihoz: {
      full: "avatarjihoz",
    },
    kongz: {
      full: tokenNum,
    },
    kongzronin: {
      full: tokenNum,
    },
    kongzvxeth: {
      tokenid: tokenNum,
    },
    kongzvxronin: {
      tokenid: tokenNum,
    },
    kittyinu: {
      kittyinu: "kittyinu",
    },
    machinearena: {
      machinearena: "machinearena",
    },
    mastercat: {
      full: "default",
    },
    moonbirds: {
      full: tokenNum,
    },
    nyancat: {
      tokenid: tokenNum,
    },
    nyancatballoon: {
      tokenid: tokenNum,
    },
    playember: {
      playember: "playember",
    },
    thebibiz: {
      avatar: tokenNum,
    },
    squishiverse: {
      tokenid: tokenNum,
    },
    tribestersnexus: {
      tribestersnexus: "tribestersnexus",
    },
    tribesters: {
      tier: "common",
    },
    pixelmon: {
      pixelmon: "pixelmon"
    },
    pixelmontrainers: {
      pixelmon: "pixelmon"
    }
  };

  const defaultTraitMapping = {
    llamaverse: {
      arms: "gold",
      skin: "gold",
      mouth: "straight",
      eyes: "redeyes",
      headgear: "crown",
    },
    kongzronin: {
      full: "default",
    },
    zenapes: {
      skin: "purple",
      face: "derp2",
    },
  };

  const specialTraits = ["oneofone", "1/1", "1of1", "11", "typetrait", "rug"];

  const getPossibleVariations = (trait) => {
    // Define possible variations for the trait
    const variations = {
      oneofone: ["oneofone", "1/1", "1of1", "11"],
      "1/1": ["oneofone", "1/1", "1of1", "11"],
      "1of1": ["oneofone", "1/1", "1of1", "11"],
      11: ["oneofone", "1/1", "1of1", "11"],
      typetrait: ["type", "typetrait"],
      rug: ["rugs"],
    };

    // Standardize and return variations for the given trait
    const standardizedTrait = trait.toLowerCase().replace(/\s+/g, "");
    return variations[standardizedTrait] || [standardizedTrait];
  };

  const getImageUrls = (avatar, traits) => {
    return avatar.pieces
      .map((piece) => {
        let urlTemplate = ""; // Accumulate the URL template
        let valid = true; // To check if trait value is valid

        if (piece.url && piece.url.override) {
          piece.url.override.forEach((item) => {
            if (item.attribute) {
              const attribute = item.attribute.toLowerCase().replace(/\s+/g, "");
              let traitValue;
              if (specialTraits.includes(attribute)) {
                // Apply variation logic only for special traits
                const possibleVariations = getPossibleVariations(attribute);
                traitValue = possibleVariations.map((variation) => traits[variation]).find((value) => value !== undefined);
              } else {
                // Standard lookup for other traits
                traitValue = traits[attribute];
              }

              if (traitValue !== undefined) {
                urlTemplate += traitValue; // Append trait value
              } else {
                valid = false; // Invalid trait value
              }
            } else if (item.text) {
              urlTemplate += item.text; // Append static text
            }
          });

          if (valid) {
            return `${avatarBaseUrl}${avatar.id}${urlTemplate}`;
          }
        } else if (piece.attributes) {
          const traitId = piece.attributes[0]?.id?.toLowerCase().replace(/\s+/g, "");
          let traitValue;

          if (specialTraits.includes(traitId)) {
            // Apply variation logic only for special traits
            const possibleVariations = getPossibleVariations(traitId);
            traitValue = possibleVariations.map((variation) => traits[variation]).find((value) => value !== undefined);
          } else {
            // Standard lookup for other traits
            traitValue = traits[traitId];
          }

          if (traitValue !== undefined) {
            return `${avatarBaseUrl}${avatar.id}/${piece.id}/${traitValue}.png`;
          }
        }

        return undefined;
      })
      .filter(Boolean);
  };

  const combineAndDisplaySpritesheets = async (imageUrls, avatar, traits) => {
    const { width: originalFrameWidth, height: originalFrameHeight } = avatar.sprite.size;
    const numFrames = avatar.sprite.frames;

    // Load images
    const images = await Promise.all(
      imageUrls.map((url) =>
        new Promise((resolve, reject) => {
          const img = new Image();
          img.crossOrigin = "anonymous";
          img.src = url;

          img.onload = () => resolve(img);
          img.onerror = (err) => {
            console.warn(`Error loading image: ${url}`, err);
            reject(err); // Properly reject on error
          };
        }).catch((err) => {
          console.warn(`Skipping image due to error: ${err}`);
          return null;
        })
      )
    );

    // Filter out failed images
    const validImages = images.filter((img) => img !== null);

    if (validImages.length === 0 && refetching) {
      setRefetch(true);
      const newImageUrls = await refetchImagesWithDefault(avatar, traits);
      if (newImageUrls.length > 0) {
        await combineAndDisplaySpritesheets(newImageUrls, avatar, traits);
      } else {
        console.warn("No valid images to process.");
        setError("There's nothing to display for the token requested.");
        setLoading(false);
        return;
      }
    }

    const canvasWidth = Math.floor(validImages[0].width / originalFrameWidth) * originalFrameWidth;
    const canvasHeight = Math.ceil(numFrames / (canvasWidth / originalFrameWidth)) * originalFrameHeight;

    // Create canvas with white background
    const unscaledSpritesheetCanvas = document.createElement("canvas");
    unscaledSpritesheetCanvas.width = canvasWidth;
    unscaledSpritesheetCanvas.height = canvasHeight;
    const unscaledSpritesheetContext = unscaledSpritesheetCanvas.getContext("2d");
    unscaledSpritesheetContext.fillStyle = "transparent";
    unscaledSpritesheetContext.fillRect(0, 0, canvasWidth, canvasHeight);

    validImages.forEach((img) => {
      for (let frameIndex = 0; frameIndex < numFrames; frameIndex++) {
        const sx = (frameIndex % (canvasWidth / originalFrameWidth)) * originalFrameWidth;
        const sy = Math.floor(frameIndex / (canvasWidth / originalFrameWidth)) * originalFrameHeight;
        const dx = sx;
        const dy = sy;
        unscaledSpritesheetContext.drawImage(img, sx, sy, originalFrameWidth, originalFrameHeight, dx, dy, originalFrameWidth, originalFrameHeight);
      }
    });

    const firstFrameCanvas = document.createElement("canvas");
    firstFrameCanvas.width = originalFrameWidth;
    firstFrameCanvas.height = originalFrameHeight;
    const firstFrameContext = firstFrameCanvas.getContext("2d");

    firstFrameContext.clearRect(0, 0, originalFrameWidth, originalFrameHeight);
    firstFrameContext.drawImage(
      unscaledSpritesheetCanvas,
      0, // sx (source x)
      0, // sy (source y)
      originalFrameWidth, // sWidth (source width)
      originalFrameHeight, // sHeight (source height)
      0, // dx (destination x)
      0, // dy (destination y)
      originalFrameWidth, // dWidth (destination width)
      originalFrameHeight // dHeight (destination height)
    );

    // Set the image URL for the first frame
    setCombinedImageUrl(firstFrameCanvas.toDataURL("image/png"));

    // Create GIF
    const createGif = async (animationName, animation) => {
      const frames = [];
      for (let i = animation.start; i <= animation.end; i++) {
        const frameCanvas = document.createElement("canvas");
        frameCanvas.width = originalFrameWidth;
        frameCanvas.height = originalFrameHeight;
        const frameContext = frameCanvas.getContext("2d");
        const sx = (i % (canvasWidth / originalFrameWidth)) * originalFrameWidth;
        const sy = Math.floor(i / (canvasWidth / originalFrameWidth)) * originalFrameHeight;

        frameContext.drawImage(unscaledSpritesheetCanvas, sx, sy, originalFrameWidth, originalFrameHeight, 0, 0, originalFrameWidth, originalFrameHeight);

        // Check if frame is blank
        const isBlank = checkIfFrameIsBlank(frameCanvas);
        if (!isBlank) {
          frames.push(frameCanvas.toDataURL("image/png"));
        }
      }

      if (frames.length === 0) {
        console.warn("No valid frames found for GIF creation.");
        return {};
      }

      return new Promise((resolve, reject) => {
        const gif = new GIF({
          workers: 2,
          quality: 10,
          width: originalFrameWidth,
          height: originalFrameHeight,
          workerScript: process.env.PUBLIC_URL + "/gif.worker.js",
          repeat: 0,
          transparent: "true",
        });

        let framesLoaded = 0;
        const totalFrames = frames.length;

        frames.forEach((url) => {
          const img = new Image();
          img.src = url;
          img.onload = () => {
            const tempCanvas = document.createElement("canvas");
            tempCanvas.width = originalFrameWidth;
            tempCanvas.height = originalFrameHeight;

            const tempContext = tempCanvas.getContext("2d");
            tempContext.drawImage(img, 0, 0, originalFrameWidth, originalFrameHeight);
            gif.addFrame(tempCanvas, { delay: 600 / animation.frameRate });
            framesLoaded++;
            if (framesLoaded === totalFrames) {
              gif.render();
            }
          };
          img.onerror = (err) => {
            console.error("Error loading frame image:", err);
            reject(err);
          };
        });

        gif.on("finished", (blob) => {
          const gifUrl = URL.createObjectURL(blob);
          setLoading(false);
          resolve({ [animationName]: gifUrl });

          // Schedule URL revocation
          setTimeout(() => {
            URL.revokeObjectURL(gifUrl);
          }, 10000); // Adjust timeout if needed
        });

        gif.on("error", (err) => {
          console.error(`GIF creation error for animation: ${animationName},`, err);
          setLoading(false);
          reject(err);
        });
      });
    };

    // Helper function to check if a frame is blank
    const checkIfFrameIsBlank = (canvas) => {
      const ctx = canvas.getContext("2d");
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const data = imageData.data;

      for (let i = 0; i < data.length; i += 4) {
        if (data[i + 3] > 0) {
          return false; // Not blank
        }
      }
      return true; // Blank
    };

    try {
      const gifResults = await Promise.all(Object.entries(avatar.animations).map(([name, anim]) => createGif(name, anim)));
      setGifs(gifResults.reduce((acc, result) => ({ ...acc, ...result }), {}));
    } catch (err) {
      console.error("Error creating GIFs:", err);
    }
  };

  const idsToExcludeOld = new Set(["saltysharks", "galacticapes", "pixeldoges", "daoduckgenesis", "custombayc", "mocaversehonourarymocas", "dininhoopenstore"]);
  const idsToExcludeHidden = new Set(["phunks", "timelesscharacters", "glizzy", "cryptopunks", "chainrunners", "larvalads", "averagepunks"]);

  return (
    <div className="container sm:w-9/12 mx-auto h-[100%] md:h-[75vh] mt-5 p-6 rounded-lg shadow-lg bg-retrodp">
      <h1 className="text-6xl font-heading text-center text-retroegg">Avatars</h1>
      <h1 className="text-l font-heading mb-6 text-center text-retroegg">
        <i className="fa-solid fa-wand-magic-sparkles" style={{ color: "#be9337" }} /> Latest: {avatarNames}
      </h1>
      <div className="text-white text-center">
        <form onSubmit={handleGenerateClick}>
          <div>
            <select id="blockchain-filter" className="text-retropb" value={blockchainFilter} onChange={handleFilterChange}>
              <option value="all">All</option>
              <option value="ethereum">Ethereum</option>
              <option value="matic">Matic</option>
              <option value="ronin">Ronin</option>
            </select>
          </div>

          <select className="text-black" id="avatar-select" value={selectedAvatar} onChange={handleDropdownChange}>
            <option className="text-retrobg">--Select an Avatar--</option>
            {avatars
              .filter((avatar) => (blockchainFilter === "all" || avatar.chain === blockchainFilter) && !idsToExcludeOld.has(avatar.id) && !idsToExcludeHidden.has(avatar.id))
              .sort((a, b) => {
                const nameA = (a.customName || a.id).toLowerCase();
                const nameB = (b.customName || b.id).toLowerCase();
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0;
              })
              .map((avatar) => (
                <option key={avatar._id} value={avatar._id}>
                  {avatar.customName || avatar.id}
                </option>
              ))}
          </select>

          <input type="text" placeholder="Number" value={tokenNum} onChange={(e) => setTokenNum(e.target.value)} className="ml-2 p-1 w-20 text-black" />
          <button type="submit" className="mt-2 sm:ml-2 p-1 bg-retrobg text-white rounded hover:bg-retropink">
            Generate GIF
          </button>
        </form>

        <div className="relative group">
          <div className="text-sm text-retrogrey">
            <div className="mt-2">Disclaimer</div>
            <div className="absolute left-5 bg-retropurple rounded hidden group-hover:block text-sm text-white p-1 mt-2 text-center sm:absolute sm:left-60 sm:top-1">
              The following collections have hidden traits and cannot be shown: {Array.from(idsToExcludeHidden).join(", ")}
              <br />I am not associated with any of these projects.
            </div>
          </div>
        </div>

        {error && <p className="text-red-500">{error}</p>}

        {loading ? (
          <LoadingSpinner />
        ) : (
          <div className="flex flex-col">
            {avatarInfo && (
              <div className="avatarInfo flex w-full md:w-[60%] md:max-w-[60%] bg-retropb border-2 rounded-lg border-white shadow-lg p-4 mx-auto justify-center">
                <div className="flex flex-col items-center space-x-4">
                  <h2 className="text-3xl">Avatar Info</h2>
                  <div className="text-sm" dangerouslySetInnerHTML={{ __html: avatarInfo }} />
                </div>
              </div>
            )}

            <div className="flex flex-row justify-center md:flex-row mt-10 gap-5">
              <div className="image-container flex-auto max-w-32">
                {combinedImageUrl && <img src={combinedImageUrl} alt="Sprite" style={{ transform: "scale(2.5)", width: "auto", height: "auto", display: "inline-block" }} />}
              </div>

              <div className="flex flex-wrap justify-center gap-5 item-center">
                {Object.keys(gifs).map((animationName) => (
                  <div key={animationName} className="gif-item">
                    <h2 className="animation">{animationName}</h2>
                    <div className="gif-box">
                      <img src={gifs[animationName]} alt={`${animationName} GIF`} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Avatars;
